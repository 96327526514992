import { createContext } from "react";

export const AuthContext = createContext({
    username: null,
    userFirstName: null,
    userLastName: null,
    userId: null,
    isLoggedIn: false,
    isVerified: false,
    login: (username, userFirstName, userLastName, userId, verified, expirationDate) => { },
    logout: (navigateToRoot = true) => { },
    setUserInfo: (userInfo) => { }
});