export const env = process.env.NODE_ENV;

const cdnRoot = `https://cdn.gandpconstruction.com`;

export const ENDPOINTS = {
    cdnRoot,
    cdn: `${cdnRoot}/storage/sites|gnp-dcauctions`,
    frontdoor: 'https://frontdoor.gandpconstruction.com/sites/gnp-dcauctions',
    gandp: env === 'production' ? `https://gandpconstruction.com` : `http://localhost:8000`
};

export const CONTACT = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    signMeUp: true
};

export const SUBSCRIBE = {
    email: '',
    source: 'dcauctions.com',
    isLoading: false
};

export const USER = {
    email: '',
    firstName: '',
    lastName: '',
    userId: '',
    isLoggedIn: false,
    verified: false
}

export const AUTH = {
    firstName: '',
    lastName: '',
    username: '',
    rememberMe: false,
    verified: false,
    isLoggedIn: false
};

export const UI = {
    isSignUpOpen: false,
    isLoginOpen: false,
    isContactOpen: false,
    isScrolled: false,
    isMenuOpen: false
};

export const State = {
    New: () => {
        return {
            ui: UI,
            contact: CONTACT,
            subscribe: SUBSCRIBE,
            auth: AUTH,
            user: USER
        };
    }
};

export type TState = typeof State.New;