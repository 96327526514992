import '../App.css';
import { BlankPage } from './BlankPage';
import { Skeleton } from 'antd';

export const Unauthorized = ({ loading }) => {
    return <>
        <BlankPage width={1000}>
            {loading ? <Skeleton active /> : <h1>401 Unauthorized</h1>}
        </BlankPage>
    </>;
}

export default Unauthorized;