import { CONTACT, ENDPOINTS, State } from "./State";
import StateSelect from "./StateSelect";
import { useHookstate } from "@hookstate/core";
import { Fragment, useRef, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthContext } from "./context/auth-context";
import { MainPage } from './pages/MainPage'
import { Login } from './pages/Login';
import { Header } from './components/Header'
import { RegisterModal } from './components/RegisterModal'
import { LoginModal } from './components/LoginModal'
import Unauthorized from "./pages/Unauthorized";
import UserProfile from "./pages/UserProfile";
import AccountSettings from "./pages/AccountSettings";
import { notification } from "antd";
import './App.css';

let logoutTimer;

export const App = () => {
  const navigate = useNavigate();
  const state = useHookstate(State.New());
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState<Date>();
  const [userVerified, setUserVerified] = useState(false);

  const login = useCallback((username, userFirstName, userLastName, userId, verified, expirationDate) => {
    setUsername(username);
    setUserFirstName(userFirstName);
    setUserLastName(userLastName);
    setUserId(userId);
    setUserLoggedIn(true);
    setUserVerified(verified);
  }, []);

  const resetUserState = () => {
    setUsername(null);
    setUserFirstName(null);
    setUserLastName(null);
    setUserId(null);
    setUserLoggedIn(false);
  };

  const logout = useCallback(async (navigateToRoot) => {
    // Remove dealerr_cookie
    await fetch(`${process.env.REACT_APP_FNV4_ADDRESS}/api/dealerr/logout`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.success) {
          resetUserState();
        } else {
          navigateToRoot = false;
        }
      })
      .catch(err => {
        notification.error({ message: `We were unable to sign you out, please try again later. Reason: ${err ? err : "Unknown"}` });
        navigateToRoot = false;
      });

    if (navigateToRoot)
      navigate("/");
  }, []);

  const setUserInfo = useCallback(userInfo => {
    setUserFirstName(userInfo.firstName);
    setUserLastName(userInfo.lastName);
  }, []);

  // Logs in if valid token exists in cookies
  const authorizeUser = useCallback(async () => {
    setLoading(true);
    await fetch(`${process.env.REACT_APP_FNV4_ADDRESS}/api/dealerr/auth`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.success) {
          data = data.payload;
          login(
            data.username,
            data.firstName,
            data.lastName,
            data.userId,
            data.verified,
            null
          );
        } else {
          resetUserState();
        }
        setLoading(false);
      })
      .catch(err => {

      });
  }, []);

  // auto-login
  useEffect(() => {    
    try {
      authorizeUser();
    }
    catch (err) { }
  }, [login, authorizeUser]);

  let routes;
  if (userLoggedIn) {

  } else {

  }

  return <>
    <AuthContext.Provider
      value={{
        username: username,
        userFirstName: userFirstName,
        userLastName: userLastName,
        userId: userId,
        isLoggedIn: !!userId,
        isVerified: userVerified,
        login: login,
        logout: logout,
        setUserInfo: setUserInfo
      }}
    >
      <RegisterModal ui={state.ui} />
      <LoginModal ui={state.ui} />
      <div className="bg-gray-800 min-h-custom relative flex flex-col justify-start">
        <Header state={state} onViewProfile={() => navigate('/profile')} onViewSettings={() => navigate('/accountsettings')} />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/profile" element={userLoggedIn ? <UserProfile /> : <Unauthorized loading={loading} />} />
          <Route path="/accountsettings" element={userLoggedIn ? <AccountSettings /> : <Unauthorized loading={loading} />} />
          <Route path="/verify/:code" element={<MainPage />} />
        </Routes>
        <footer className="text-white flex items-end h-[50px] p-3 justify-center w-full">
          <div>
            &copy; Copyright {(new Date()).getFullYear()} DealerR Inc.
          </div>
        </footer>
      </div>
    </AuthContext.Provider>
  </>;
}

export default App;