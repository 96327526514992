import '../App.css';

export const BlankPage = ({ children, width }) => {
    return <>
        <div className="min-h-custom flex justify-center">
            <div className="flex w-full">
                <div className="flex-1 bg-gray-300"></div>
                <div className="w-80 bg-white" style={{ width: width, padding: 30 }}>
                    {children}
                </div>
                <div className="flex-1 bg-gray-300"></div>
            </div>
        </div>
    </>;
};

export default BlankPage;