import { CONTACT, ENDPOINTS, State } from "../State";
import StateSelect from "../StateSelect";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { AuthContext } from "../context/auth-context";
import type { MenuProps } from 'antd';
import { Button, Dropdown, Space } from 'antd';
import { DownOutlined, SettingOutlined, ProfileOutlined, LogoutOutlined } from '@ant-design/icons';

export const Header = ({ state, onViewProfile, onViewSettings }) => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    const logout = useCallback(() => {
        auth.logout();
    }, []);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const style = {
        cursor: isHovered ? 'pointer' : 'default'
    };

    const items: MenuProps['items'] = [
        {
            label: (
                <a onClick={onViewProfile}>
                    View Profile
                </a>
            ),
            icon: <ProfileOutlined />,
            key: '0',
        },
        {
            label: (
                <a onClick={onViewSettings}>
                    Account Settings
                </a>
            ),
            icon: <SettingOutlined />,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: (
                <a onClick={logout}>
                    Sign out
                </a>
            ),
            icon: <LogoutOutlined />,
            key: '2',
        }
    ];

    return <>
        <header className="h-[100px] w-full grow-0 flex justify-center items-center">
            <div className="w-full flex justify-center items-center">
            </div>
            <div className="w-full flex justify-center items-center">
                <a onClick={() => { navigate("/"); }}><img src="/images/dealerr.png" alt="DealerR" className="h-[50px] w-auto" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={style} /></a>
            </div>
            <div className="w-full flex justify-center items-center">
                {
                    auth.isLoggedIn ?
                        <Dropdown menu={{ items }} >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space style={{ color: 'white' }}>
                                    Hello, {auth.userFirstName ? auth.userFirstName : auth.userFirstName}
                                    <DownOutlined style={{ color: 'white' }} />
                                </Space>
                            </a>
                        </Dropdown>
                        :
                        <>
                            <Button onClick={() => state.ui.isSignUpOpen.set(true)} type="text" style={{ color: 'white' }}>Register</Button>
                            <Button onClick={() => state.ui.isLoginOpen.set(true)} type="text" style={{ color: 'white' }}>Sign in</Button>
                        </>
                }
            </div>
        </header>
    </>;
}

export default Header;