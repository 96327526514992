import { useContext, useState } from "react";
import { AuthContext } from "../context/auth-context";
import { Button, Modal, Checkbox, Form, Input, Typography, Result } from "antd";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import type { FormProps } from 'antd';
const { Text } = Typography;

export const LoginModal = ({ ui }) => {
    const auth = useContext(AuthContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState('');
    const [loginSuccessful, setLoginSuccessful] = useState(false);

    type FieldType = {
        username?: string;
        password?: string;
        remember?: string;
    };

    const exit = () => {
        setFormError('');
        setLoginSuccessful(false);
        form.resetFields();
        ui.isLoginOpen.set(false);
    };

    const exitWithSuccess = () => {
        setLoginSuccessful(true);
        setTimeout(exit, 3000);
    };

    const login: FormProps<FieldType>['onFinish'] = async (values) => {

        setLoading(true);
        let data = {
            email: values.username,
            password: values.password,
            site: "https://dealerr.com"
        };
        let resData: any;
        let shouldAuthenticate = true;

        await fetch(`${process.env.REACT_APP_FNV4_ADDRESS}/api/dealerr/login`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (!data.success) {
                    shouldAuthenticate = false;
                    setFormError(data.message ? data.message : "An unknown error occured. Please try again later.");
                }
                setLoading(false);
            })
            .catch(err => {
                shouldAuthenticate = false;
                setFormError(err ? err : "An unknown error occured. Please try again later.");
                setLoading(false);
            });

        if (!shouldAuthenticate)
            return;

        await fetch(`${process.env.REACT_APP_FNV4_ADDRESS}/api/dealerr/auth`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.success) {
                    resData = data.payload;
                    setFormError('');
                    auth.login(
                        resData.username,
                        resData.firstName,
                        resData.lastName,
                        resData.userId,
                        resData.verified,
                        null
                    );
                    exitWithSuccess();
                } else {
                    setFormError(data.message ? data.message : "An unknown error occured. Please try again later.");
                }
            })
            .catch(err => {
                setFormError(err ? err : "An unknown error occured. Please try again later.");
            });

        setLoading(false);
    };

    return <div>
        <Modal title="Sign in"
            centered
            open={ui.isLoginOpen.get()}
            onCancel={exit}
            width={450}
            footer={null}>
            {loginSuccessful ?
                <Result
                    status="success"
                    title="Successfully signed in!"
                />
                :
                <Form
                    form={form}
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={login}
                    style={{ paddingTop: 20 }}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your email address!' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email address" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }}>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item>
                            </div>
                            <div className="flex justify-end" style={{ flex: 1 }}>
                                <a className="login-modal-forgot-password-link" href="">
                                    Forgot password
                                </a>
                            </div>
                        </div>
                    </Form.Item>
                    {formError !== '' ? <Text style={{ marginBottom: 5 }} type="danger">{formError}</Text> : <></>}
                    <Form.Item style={{ marginBottom: 10 }}>
                        <Button loading={loading} style={{ width: '100%' }} type="primary" htmlType="submit" className="login-form-button">
                            Sign in
                        </Button>
                    </Form.Item>
                </Form>
            }
        </Modal>
    </div>
};

export default LoginModal;