interface IGeo {
    Id: string;
    Lat: number;
    Lng: number;
    State: string;
}

export class Program {
    // private RESTRICTION = { 
    //   latLngBounds: new google.maps.LatLngBounds(
    //     { lat: 17, lng: -159 }, 
    //     { lat: 73, lng: -65 }
    //   )
    // };
    private SERVICE_URL = `https://api.gandpconstruction.com`;
    private US_CENTER = { lat: 38.828723, lng: -92.7730812 };
    private US_ZOOM = 4.8;
    private STATE_ZOOM = 6.3;
    private PIN_ICONS = { 'green': 'http://maps.google.com/mapfiles/kml/paddle/grn-blank.png', 'blue': 'https://maps.google.com/mapfiles/kml/paddle/blu-blank.png' };
    private SETTINGS = {
        mapConfig: {
            maxZoom: 10,
            minZoom: 4.8,
            //restriction: this.RESTRICTION,
            zoom: this.US_ZOOM,
            center: this.US_CENTER,
            disableDefaultUI: true,
            // mapTypeControl: true,
            // scaleControl: true,    
            zoomControl: true,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        },
        serviceUrl: this.SERVICE_URL
    };

    private data = {
        geos: [] as IGeo[],
        markers: [] as google.maps.Marker[],
        preferred_dealers: [] as string[]
    };
    private elements = { map: null as unknown as HTMLDivElement, select: null as unknown as HTMLSelectElement };

    private map: google.maps.Map;
    private geocoder: google.maps.Geocoder;
    private infowindow: google.maps.InfoWindow;
    private settings = this.SETTINGS;

    constructor(w, map, select) {
        this.elements.map = map;
        this.elements.select = select;

        w.gandp.toggle_preferred = (id: string) => {
            let isActive = this.isActive(id);

            this.data.preferred_dealers = isActive
                ? this.data.preferred_dealers.filter(d => d !== id)
                : [...this.data.preferred_dealers.filter(d => d !== id), id];

            this.data.markers.forEach(m => {
                let g = m["geo"] as IGeo;

                if (g.Id == id) {
                    m.setIcon({ ...m.getIcon() as google.maps.Icon, url: isActive ? this.PIN_ICONS.blue : this.PIN_ICONS.green });
                    this.infowindow.setContent(this.buildMarkerContent(id));
                }
            });
        };

        this.infowindow = new google.maps.InfoWindow({ content: `<div>Loading...</div>` });
        this.map = new google.maps.Map(this.elements.map, this.settings.mapConfig);
        this.geocoder = new google.maps.Geocoder();

        this.map.addListener('center_changed', () => {
            this.setMarkersByBounds();
        });
        this.elements.select.addEventListener("change", async () => {
            if (this.elements.select.value) {
                let [place] = await this.geocode();
                let isAll = this.elements.select.value == 'ALL';

                this.clearMarkers();
                this.setMarkers(this.elements.select.value);

                this.map.setZoom(isAll ? this.US_ZOOM : this.STATE_ZOOM);
                this.map.panTo(isAll ? this.US_CENTER : { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
            }
        });

        w.gandp.program = this;
    }

    private setMarkersByBounds = () => {
        let bounds = this.map.getBounds();
        if (!bounds) { return; }

        this.data.markers.forEach(m => {
            let g = m["geo"] as IGeo;

            if (g.Lat >= bounds!.getSouthWest().lat()
                && g.Lat <= bounds!.getNorthEast().lat()
                && g.Lng >= bounds!.getSouthWest().lng()
                && g.Lng <= bounds!.getNorthEast().lng()) {
                m.setMap(this.map);
            } else {
                m.setMap(null);
            }
        });
    };

    private isActive = id => this.data.preferred_dealers.indexOf(id) !== -1;

    private clearMarkers = () => {
        this.data.markers.forEach(m => { m.setMap(null); });
    };

    private buildMarkerContent = (dealerId: string) => {
        return `<div style="width: 200px; padding: 10px; ">
        <div>Dealer: <strong>${dealerId}</strong></div>
        <hr />
        <div onclick="gandp.toggle_preferred('${dealerId}')">${this.isActive(dealerId.toString()) ? 'Remove from' : 'Add to'} Preferred</div>
      </div>`;
    };

    private setMarkers = (state: string) => {
        this.data.markers.forEach((m: google.maps.Marker) => {
            let g = m['geo'];

            if (state !== 'ALL' && g.state !== state) { m.setMap(null); }
            else { m.setMap(this.map); }
        });
    };

    private addMarkers = (state: string) => {
        this.data.geos.forEach(g => {
            if (state !== 'ALL' && g.State !== state) { return; }

            var marker = new google.maps.Marker({
                position: { lat: g.Lat, lng: g.Lng },
                map: this.map,
                title: "Dealer " + g.Id,

                icon: {
                    url: this.PIN_ICONS.blue,
                    scaledSize: new google.maps.Size(20, 20),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(0, 0)
                }
            });
            marker["geo"] = g;

            marker.addListener('click', () => {
                this.infowindow.open({ anchor: marker, map: this.map, shouldFocus: false });
                this.infowindow.setContent(this.buildMarkerContent(g.Id));
                this.map.panTo({ lat: g.Lat, lng: g.Lng });

                //marker.setIcon({ ...marker.getIcon() as google.maps.Icon, url: PIN_ICONS.green });        
            });

            this.data.markers.push(marker);
        });
    };

    private getDealerships = () => new Promise<void>((resolve, reject) => {
        try {
            let xhr = new XMLHttpRequest();
            xhr.open("get", this.settings.serviceUrl + '/geos/dealer');
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    this.data.geos = JSON.parse(xhr.responseText);
                    resolve();
                }
            };
            xhr.send();
        } catch (error) { console.log(error); reject(error); }
    });

    private geocode = () => new Promise<google.maps.GeocoderResult[]>((resolve => {
        this.geocoder.geocode({ 'address': this.elements.select.options[this.elements.select.selectedIndex].innerText }, (results, status) => {
            resolve(results || []);
        });
    }));

    public run = async () => {
        await this.getDealerships();

        this.addMarkers(this.elements.select.value);
        this.map.setZoom(4);
    };
};