import { CONTACT, ENDPOINTS, State } from "../State";
import StateSelect from "../StateSelect";
import { useHookstate } from "@hookstate/core";
import { useRef, useEffect, useState, useCallback, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import '../App.css';
import type { FormProps } from 'antd';
import { Button, Checkbox, Form, Input } from 'antd';
import { BlankPage } from './BlankPage';
import { AuthContext } from "../context/auth-context";

export const UserProfile = () => {
    const auth = useContext(AuthContext);

    const handleTestClick = useCallback(async () => {
        await fetch(`${process.env.REACT_APP_FNV4_ADDRESS}/api/dealerr/testredirect`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
    }, []);

    return <>
        <BlankPage width={1000}>
            <h1>{auth.userFirstName} {auth.userLastName}'s profile</h1>
            {/* <Button onClick={handleTestClick}>Test Redirect</Button> */}
        </BlankPage>
    </>;
}

export default UserProfile;