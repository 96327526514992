import React, { useState, useCallback } from "react";
import { Button, Modal, Form, Input, message, Typography } from "antd";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
const { Text } = Typography;

export const RegisterModal: React.FC<{ ui: any }> = ({ ui }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    let bValidateEmailAvailability = false;

    type FieldType = {
        firstname?: string;
        lastname?: string;
        username?: string;
        password?: string;
        password2?: string;
    };

    const exit = useCallback(() => {
        form.resetFields();
        setLoading(false);
        setFormErrorMessage('');
        bValidateEmailAvailability = false;
        ui.isSignUpOpen.set(false);
    }, []);

    const validateEmail = useCallback(async (_, value) => {
        if (bValidateEmailAvailability) {
            const values = form.getFieldsValue();

            if (!values.username) {
                return Promise.reject(new Error('Please input your email address!'));
            }

            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regex.test(values.username)) {
                return Promise.reject(new Error('Please enter a valid email address.'));
            }

            let data = {
                firstName: values.firstname,
                lastName: values.lastname,
                email: values.username,
                password: values.password,
                site: "https://dealerr.com"
            };
            console.log("before register call");
            let r: any = await (await fetch(`${process.env.REACT_APP_FNV4_ADDRESS}/api/dealerr/register`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })).json();

            if (r.success) {
                return Promise.resolve();
            } else if (r.message === "Email address already exists.") {
                return Promise.reject(new Error('Email address already registered!'));
            } else {
                setFormErrorMessage(r.message);
                return Promise.resolve();
            }
        } else {
            if (!value || value == '') {
                return Promise.reject(new Error('Please input your email address!'));
            }
        }
    }, []);

    const handleSubmit = useCallback(async () => {
        setLoading(true);
        bValidateEmailAvailability = true;
        await form.validateFields()
            .then((values) => {
                bValidateEmailAvailability = false;
                // successful registration
                setFormErrorMessage('');
                setLoading(false);
                message.success("Registration successful! Please check your email and verify your account.");
                exit();
            })
            .catch((errorInfo) => {
                bValidateEmailAvailability = false;
                // failed registration, set form error if it's not related to the username field
                if (errorInfo.errorFields[0]?.name[0]!! !== 'username')
                    setFormErrorMessage(errorInfo.errorFields[0]?.errors[0] ? errorInfo.errorFields[0]?.errors[0] : "An unknown error occurred.");
            });

        bValidateEmailAvailability = false;
        setLoading(false);
    }, []);

    return <div>
        <Modal title="DealerR Registration"
            centered
            open={ui.isSignUpOpen.get()}
            onCancel={exit}
            width={450}
            footer={null} >
            <Form
                form={form}
                name="register"
                style={{ marginTop: 30, marginBottom: 10 }}
                initialValues={{ remember: true }}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    name="firstname"
                    rules={[{ required: true, message: 'Please input your first name!' }]}
                    style={{ marginBottom: 30 }}
                >
                    <Input placeholder="First name" />
                </Form.Item>

                <Form.Item<FieldType>
                    name="lastname"
                    rules={[{ required: true, message: 'Please input your last name!' }]}
                    style={{ marginBottom: 30 }}
                >
                    <Input placeholder="Last name" />
                </Form.Item>

                <Form.Item
                    name="username"
                    rules=
                    {[
                        { validator: validateEmail }
                    ]}
                    style={{ marginBottom: 30 }}                    
                >
                    <Input onChange={() => bValidateEmailAvailability = false} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email address" />
                </Form.Item>

                <Form.Item
                    name="password" rules={[{ required: true, message: 'Please input a password!' }]}
                    style={{ marginBottom: 30 }}>
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>

                <Form.Item
                    name="confirm password"
                    dependencies={['password']}
                    style={{ marginBottom: formErrorMessage === '' ? 8 : 30 }}
                    rules={[
                        {
                            required: true, message: 'Please confirm your password!'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The passwords do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Confirm password"
                    />
                </Form.Item>
                {formErrorMessage !== '' ? <Text style={{ marginBottom: 5 }} type="danger">{formErrorMessage.toString()}</Text> : <></>}
                <div style={{ width: '100%', display: 'flex' }}>
                    <Button type="primary" onClick={handleSubmit} loading={loading} style={{ flex: 1, marginTop: 30 }} >
                        Submit
                    </Button>
                </div>
            </Form>
        </Modal>
    </div>
};

export default RegisterModal;