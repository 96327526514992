import { CONTACT, ENDPOINTS, State } from "../State";
import StateSelect from "../StateSelect";
import { useHookstate } from "@hookstate/core";
import { useRef, useEffect, useState, useCallback, useContext, useMemo, ChangeEvent } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import '../App.css';
import type { FormProps } from 'antd';
import { Button, Typography, Form, Input, Card, Space, Skeleton, notification } from 'antd';
import { BlankPage } from './BlankPage';
import { AuthContext } from "../context/auth-context";
const { Text, Link } = Typography;

type InfoFieldType = {
    firstName?: string;
    lastName?: string;
};

type PasswordFieldType = {
    currentPassword?: string;
    newPassword?: string;
    newPasswordConfirm?: string;
};

const validateName = (fieldProps, value) => {
    let fieldName = "";
    if (fieldProps?.field == 'firstName')
        fieldName = "First name";
    else if (fieldProps?.field == 'lastName')
        fieldName = "Last name";

    if (!value || value == '')
        return Promise.reject(new Error(`${fieldName} is required`));
    else
        return Promise.resolve();
};

export const AccountSettings = () => {
    const auth = useContext(AuthContext);
    const [infoForm] = Form.useForm();
    const [passwordForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [infoSaveButtonLoading, setInfoSaveButtonLoading] = useState(false);
    const [infoSaveButtonDisabled, setInfoSaveButtonDisabled] = useState(true);
    const [passwordSaveButtonLoading, setPasswordSaveButtonLoading] = useState(false);
    const [infoOperationSuccessful, setInfoOperationSuccessful] = useState(false);
    const [infoOperationFailed, setInfoOperationFailed] = useState(false);
    const [passwordOperationSuccessful, setPasswordOperationSuccessful] = useState(false);
    const [passwordOperationFailed, setPasswordOperationFailed] = useState('');
    const [initialValues, setInitialValues] = useState({ firstName: '', lastName: '', remember: true });
    let firstNameChanged = false, lastNameChanged = false;

    const onInfoFinish: FormProps<InfoFieldType>['onFinish'] = async (values) => {
        setInfoSaveButtonLoading(true);
        await fetch(`${process.env.REACT_APP_FNV4_ADDRESS}/api/dealerr/users?id=${auth.userId}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userId: auth.userId,
                fieldNames: ["firstName", "lastName"],
                firstName: values.firstName,
                lastName: values.lastName
            })
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                auth.setUserInfo(data.payload);
                setInfoSaveButtonLoading(false);
                setInfoSaveButtonDisabled(true);
                setInfoOperationFailed(false);
                setInfoOperationSuccessful(true);
                setInitialValues({ firstName: data.payload.firstName, lastName: data.payload.lastName, remember: true });
            })
            .catch(err => {
                setInfoSaveButtonLoading(false);
                setInfoSaveButtonDisabled(true);
                setInfoOperationFailed(true);
            });
    };

    const onPasswordFinish: FormProps<PasswordFieldType>['onFinish'] = async (values) => {
        setPasswordSaveButtonLoading(true);
        await fetch(`${process.env.REACT_APP_FNV4_ADDRESS}/api/dealerr/users?id=${auth.userId}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userId: auth.userId,
                fieldNames: ["password"],
                currentPassword: values.currentPassword,
                password: values.newPassword
            })
        })
            .then(response => {
                return response.json();
            })
            .then(data => {                
                setPasswordSaveButtonLoading(false);

                if (data.success) {
                    passwordForm.resetFields();
                    setPasswordOperationFailed('');
                    setPasswordOperationSuccessful(true);
                }
                else {
                    passwordForm.resetFields(['currentPassword']);
                    setPasswordOperationSuccessful(false);
                    setPasswordOperationFailed(data.message);
                }
            })
            .catch(err => {
                setPasswordSaveButtonLoading(false);
                setPasswordOperationSuccessful(false);
                setPasswordOperationFailed(err);
            });
    };

    const onInfoFinishFailed: FormProps<InfoFieldType>['onFinishFailed'] = (errorInfo) => {

    };

    const onPasswordFinishFailed: FormProps<PasswordFieldType>['onFinishFailed'] = (errorInfo) => {

    };

    useEffect(() => {
        const getUserData = async () => {
            await fetch(`${process.env.REACT_APP_FNV4_ADDRESS}/api/dealerr/users?id=${auth.userId}`, {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setLoading(false);
                    setInitialValues({ firstName: data.payload.firstName, lastName: data.payload.lastName, remember: true });
                    infoForm.setFieldsValue({ firstName: data.payload.firstName, lastName: data.payload.lastName });
                })
                .catch(err => {
                    setLoading(false);
                });
        };

        setLoading(true);
        getUserData();
    }, []);

    const handleFirstNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (infoOperationSuccessful)
            setInfoOperationSuccessful(false);

        if (initialValues.firstName == event.target.value) {
            firstNameChanged = false;
        } else {
            firstNameChanged = true;
        }

        if (!firstNameChanged && !lastNameChanged)
            setInfoSaveButtonDisabled(true);
        else
            setInfoSaveButtonDisabled(false);
    }, [initialValues.firstName]);

    const handleLastNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (infoOperationSuccessful)
            setInfoOperationSuccessful(false);

        if (initialValues.lastName == event.target.value) {
            lastNameChanged = false;
        } else {
            lastNameChanged = true;
        }

        if (!firstNameChanged && !lastNameChanged)
            setInfoSaveButtonDisabled(true);
        else
            setInfoSaveButtonDisabled(false);
    }, [initialValues.lastName]);

    // const handleCurrentPasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    //     if (infoOperationSuccessful)
    //         setInfoOperationSuccessful(false);

    //     if (initialValues.lastName == event.target.value) {
    //         lastNameChanged = false;
    //     } else {
    //         lastNameChanged = true;
    //     }

    //     if (!firstNameChanged && !lastNameChanged)
    //         setInfoSaveButtonDisabled(true);
    //     else
    //         setInfoSaveButtonDisabled(false);
    // }, []);

    // const handleNewPasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    //     if (infoOperationSuccessful)
    //         setInfoOperationSuccessful(false);

    //     if (initialValues.lastName == event.target.value) {
    //         lastNameChanged = false;
    //     } else {
    //         lastNameChanged = true;
    //     }

    //     if (!firstNameChanged && !lastNameChanged)
    //         setInfoSaveButtonDisabled(true);
    //     else
    //         setInfoSaveButtonDisabled(false);
    // }, []);

    // const handleConfirmPasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    //     if (infoOperationSuccessful)
    //         setInfoOperationSuccessful(false);

    //     if (initialValues.lastName == event.target.value) {
    //         lastNameChanged = false;
    //     } else {
    //         lastNameChanged = true;
    //     }

    //     if (!firstNameChanged && !lastNameChanged)
    //         setInfoSaveButtonDisabled(true);
    //     else
    //         setInfoSaveButtonDisabled(false);
    // }, []);

    const onResendVerificationEmail = useCallback(async () => {
        await fetch(`${process.env.REACT_APP_FNV4_ADDRESS}/api/dealerr/verify/sendemail`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: auth.userId })
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.success)
                    notification.success({ message: 'Email sent successfully! Please check your inbox and spam folder.', duration: 5000 });
                else
                    notification.error({ message: `Email failed to send! Error: ${data.message ? data.message : "Unknown"}`, duration: 5000 });
            })
            .catch(err => {
                notification.error({ message: `Email failed to send! Error: ${err ? err : "Unknown"}`, duration: 5000 });
            });
    }, []);

    return <>
        <BlankPage width={1000}>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                {
                    !auth.isVerified ?
                        <Space direction="horizontal">
                            <Text>Account status: Unverified</Text>
                            <Link onClick={onResendVerificationEmail}>Resend verification email</Link>
                        </Space>
                        :
                        <></>
                }

                <Card title="Basic info" size="default">
                    {loading ? <Skeleton active /> :
                        <Form
                            name="basicInfo"
                            form={infoForm}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            style={{ maxWidth: 600 }}
                            initialValues={initialValues}
                            onFinish={onInfoFinish}
                            onFinishFailed={onInfoFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item<InfoFieldType>
                                label="First name"
                                name="firstName"
                                validateTrigger={"onBlur"}
                                rules={[{ validator: validateName }]}
                            >
                                <Input onChange={handleFirstNameChange} />
                            </Form.Item>

                            <Form.Item<InfoFieldType>
                                label="Last name"
                                name="lastName"
                                validateTrigger={"onBlur"}
                                rules={[{ validator: validateName }]}
                            >
                                <Input onChange={handleLastNameChange} />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Space>
                                    <Button type="primary" htmlType="submit" disabled={infoSaveButtonDisabled} loading={infoSaveButtonLoading}>
                                        Save
                                    </Button>
                                    {infoOperationSuccessful ? <Text type="success">Success!</Text> : <></>}
                                    {infoOperationFailed ? <Text type="danger">An error occured, please try again later.</Text> : <></>}
                                </Space>
                            </Form.Item>
                        </Form>
                    }
                </Card>
                <Card title="Change password" size="default">
                    <Form
                        name="changePassword"
                        form={passwordForm}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        style={{ maxWidth: 600 }}
                        initialValues={{ remember: true }}
                        onFinish={onPasswordFinish}
                        onFinishFailed={onPasswordFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item<PasswordFieldType>
                            label="Current password"
                            name="currentPassword"
                            rules={[{ required: true, message: "Current password is required" }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item<PasswordFieldType>
                            label="New password"
                            name="newPassword"
                            rules={[
                                {
                                    required: true, message: 'New password is required'
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (getFieldValue('currentPassword') === value) {
                                            return Promise.reject(new Error('New password must be different than current password'));
                                        }
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The passwords do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item<PasswordFieldType>
                            label="Confirm new password"
                            name="newPasswordConfirm"
                            rules={[
                                {
                                    required: true, message: 'Please confirm your password'
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The passwords do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={passwordSaveButtonLoading}>
                                    Save
                                </Button>
                                {passwordOperationSuccessful ? <Text type="success">Success!</Text> : <></>}
                                {passwordOperationFailed ? <Text type="danger">{passwordOperationFailed}</Text> : <></>}
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>
            </Space>
        </BlankPage>
    </>;
}

export default AccountSettings;