import { CONTACT, ENDPOINTS, State } from "../State";
import StateSelect from "../StateSelect";
import { useHookstate } from "@hookstate/core";
import { useRef, useEffect, useContext, useState, useCallback } from "react";
import { AuthContext } from "../context/auth-context";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { Program } from "../Program";
import { notification } from "antd";

export const MainPage = () => {
  let refMap = useRef<HTMLDivElement>(null);
  let refSearch = useRef<HTMLInputElement>(null);
  let refSelect = useRef<HTMLSelectElement>(null);
  const state = useHookstate(State.New());
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const { code } = useParams();

  const validate = () => {
    if (state.contact.firstName.get()
      && state.contact.lastName.get()
      && state.contact.email.get()
      && state.contact.message.get()) {

      return true;
    }

    return false;
  };

  const send = async e => {
    e.preventDefault();
    e.stopPropagation();

    if (!validate()) { notification.error({ message: 'Please fill in all fields' }); return; }

    try {
      let r: any = await fetch(`${ENDPOINTS.gandp}/api/sendemail`, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          to: "DC Auctions",
          source: "https://dcauctions.com",
          subject: "Inquiry from www.dcauctions.com",
          name: state.contact.firstName.get() + ' ' + state.contact.lastName.get(),
          email: state.contact.email.get(),
          phone: state.contact.phone.get(),
          message: state.contact.message.get(),
          signMeUp: state.contact.signMeUp.get()
        })
      });
      r = await r.json();

      if (r.Success) {
        state.contact.set(CONTACT);

        notification.success({ message: <>{r.Messages.map((m, i) => <div key={i}>{m}</div>)}</> })
      }

    } catch (error) {
      notification.error({ message: <>Sorry, there were errors submitting your message. Please contact customer service.</> });
    }
  };

  const editField = (field: string, type: string = 'string') => e => {
    e.stopPropagation();
    e.preventDefault();

    if (type === 'boolean') {
      state[field].set(e.target.checked);
      return;
    }

    state[field].set(e.target.value);
  };

  const onSignMeUpCheck = e => {
    e.preventDefault();
    e.stopPropagation();

    state.contact.signMeUp.set(!state.contact.signMeUp.get());
  };

  const fetchData = useCallback(async (code) => {
    notification.info({ message: 'We are verifying your account...' });

    let responseData: any = await (await fetch(`${process.env.REACT_APP_FNV4_ADDRESS}/api/dealerr/verify?code=${code}`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: null
    })).json();

    if (responseData.success) {
      notification.success({ message: 'Your account has been successfully verified!' });
      navigate("/");
      
      // If they're already logged in (there is a valid cookie), we good
      // If they're not, have them log in again to regenerate their JWT and set the cookie

      // auth.logout(false);
      // auth.login(responseData.payload.userFirstName, responseData.payload.token, null);
    }
    else {
      notification.error({ message: 'There was a problem verifying your account, please try again later.' });
      auth.logout();
    }
  }, []);

  useEffect(() => {
    let w = window as any;
    w.gandp = {};

    let program = new Program(window, refMap.current, refSelect.current);
    program.run();

    if (code) {
      fetchData(code);
    }
  }, []);

  return <>
    <div className="grow relative">
      <div className="absolute left-[50%] ml-[-320px] w-[320px] z-[999999] m-2">
        <input ref={refSearch} className="p-3 bg-gray-100 w-full h-[50px] border-solid border-2 border-blue-400 shadow-2xl shadow-black" placeholder="Search..." />
      </div>
      <div className="absolute left-[50%] w-[320px] z-[99999] m-2">
        <select ref={refSelect} id="state_select" className="p-3 bg-gray-100 w-full h-[50px] border-solid border-2 border-blue-400 shadow-2xl shadow-black">
          <option value="ALL">-- Select a State ---</option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
      </div>
      <div style={{ height: "calc(100vh - 150px)" }}>
        <div className="h-full w-full z-[1]" ref={refMap}></div>
      </div>
    </div>
    <div>
      <div className="lg:p-10 flex justify-center items-center text-gray-700">
        <div className="flex flex-col w-full max-w-[640px]">
          <div className="text-center"><h2 className="text-3xl lg:4xl py-5 text-dccream">Contact Us</h2></div>
          <div className="flex flex-wrap items-stretch content-stretch justify-start">
            <div className="basis-full lg:basis-1/2 p-1"><input value={state.contact.firstName.get()} onChange={editField('firstName')} className="w-full p-2 rounded-none outline-none" placeholder="First Name" /></div>
            <div className="basis-full lg:basis-1/2 p-1"><input value={state.contact.lastName.get()} onChange={editField('lastName')} className="w-full p-2 rounded-none outline-none" placeholder="Last Name" /></div>
            <div className="basis-full lg:basis-1/2 p-1"><input value={state.contact.email.get()} onChange={editField('email')} className="w-full p-2 rounded-none outline-none" placeholder="Email" /></div>
            <div className="basis-full lg:basis-1/2 p-1"><input value={state.contact.phone.get()} onChange={editField('phone')} className="w-full p-2 rounded-none outline-none" placeholder="Phone" /></div>
            <div className="basis-full p-1"><textarea value={state.contact.message.get()} onChange={editField('message')} className="w-full p-2 rounded-none outline-none" placeholder="Message" /></div>
            <div className="basis-full p-1 py-3 px-5 lg:px-0">
              <label className="text-dccream ml-3">
                <input checked={state.contact.signMeUp.get()} type="checkbox" onChange={onSignMeUpCheck} />
                Sign me up for DC Auctions newsletter
              </label>
            </div>
            <div className="basis-full p-1 py-3"><button onClick={send} className="rounded-none w-full font-bold text-xl p-2 bg-dcorange text-gray-700">Send</button></div>
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default MainPage;