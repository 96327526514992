// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --header-height: 100px;
    --footer-height: 50px;
}
.min-h-custom {
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

.login-modal-forgot-password-link {
    color: #1677ff;
    text-decoration: none;
}

.login-modal-forgot-password-link:hover {
    color: #69b1ff;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,qBAAqB;AACzB;AACA;IACI,qEAAqE;AACzE;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[":root {\r\n    --header-height: 100px;\r\n    --footer-height: 50px;\r\n}\r\n.min-h-custom {\r\n    min-height: calc(100vh - var(--header-height) - var(--footer-height));\r\n}\r\n\r\n.login-modal-forgot-password-link {\r\n    color: #1677ff;\r\n    text-decoration: none;\r\n}\r\n\r\n.login-modal-forgot-password-link:hover {\r\n    color: #69b1ff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
